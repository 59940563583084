.aiChat {
  --header-height: 40px;
  --footer-height: 37px;
  width: 100%;
  overflow: hidden;
  height: var(--chat-height);
  min-height: calc(
    100dvh - var(--header-height) - var(--footer-height) -
      var(--outer-spacing-4) * 2
  );
  max-height: calc(100dvh - (var(--outer-spacing-4) * 2));
  justify-content: space-between;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  border-radius: inherit;
}

.aiChat[data-is-embed] {
  min-height: calc(100dvh - var(--header-height) - var(--footer-height));
}

/* Widgets like zone and section can toggle their visual separation. When the visual separation is turned on ( on either zone or section ),
  it adds a gutter padding around them. In order to account for the gutter top and bottom padding, we subtract 2x the gutter padding
  from the chat-height so that the widget does not overflow.

  So total height of widget = chat-height - gutter padding of section/zone ( 2x --outer-spacing-3 ) - gutter padding of canvas ( --outer-spacing-4 ).
  Note: in embed mode, then canvas has no paddings.

  Note that the gutter padding for section and zone is same, so it does not matter if the visual separation is on zone or section.
*/
:is(
    [elevation="1"][data-elevation="true"],
    [elevation="2"][data-elevation="true"]
  )
  .aiChat {
  --max-height: calc(var(--chat-height) - ((var(--outer-spacing-3) * 2)));
}

/** This is for the case when there is visual separation on both zone and section.
  In this case, we need to subtract 4x ( 2x top and 2x bottom since the gutter padding is on zone and section both)
  the gutter padding from the chat-height so that the widget does not overflow. This case overrides the above two cases.

  So total height of widget = chat-height - gutter padding of section - gutter padding of zone - gutter padding of canvas

  Since the gutter padding for section and zone is same, we use 4x the gutter padding ( 2x top + 2x bottom )
*/
[elevation="1"][data-elevation="true"]
  [elevation="2"][data-elevation="true"]
  .aiChat {
  --max-height: calc(var(--chat-height) - ((var(--outer-spacing-3) * 4)));
}

/**
 * ------------------------------------------------------------------------------------------------
 * HEADER
 * ------------------------------------------------------------------------------------------------
 */

.header {
  padding: var(--inner-spacing-2) var(--inner-spacing-3);
  border-bottom: 1px solid var(--color-bd-elevation-1);
  background-color: transparent;
}

.headerTitle {
  margin-inline: auto;
}

/**
 * ------------------------------------------------------------------------------------------------
 * SIDEBAR WRAPPER
 * ------------------------------------------------------------------------------------------------
 */
.sidebarWrapper {
  display: flex;
  width: 100%;
  min-height: 0;
  flex-grow: 1;
}

/**
 * ------------------------------------------------------------------------------------------------
 * CHAT HEADER
 * ------------------------------------------------------------------------------------------------
 */
.avatarImage {
  align-items: center;
  display: inline-flex;
  height: var(--icon-size-3);
  width: var(--icon-size-3);
  justify-content: center;
  flex-shrink: 0;
}

/**
 * ------------------------------------------------------------------------------------------------
 * MESSAGE LIST
 * ------------------------------------------------------------------------------------------------
 */
.messageList {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: var(--inner-spacing-4) var(--inner-spacing-6);
  overflow-y: auto;
}

[data-is-mobile] .messageList {
  padding-inline: var(--inner-spacing-4);
}

.messageListItem {
  counter-reset: citation-number;
}

.messageListItem:first-child {
  white-space: pre-wrap;
}

.userMessage {
  background-color: var(--color-bg-neutral-subtle);
  padding: var(--inner-spacing-3) var(--inner-spacing-4) var(--inner-spacing-4)
    var(--inner-spacing-5);
  border-radius: var(--border-radius-elevation-3);
  white-space: pre-wrap;
}

.assistantMessgage,
.userMessage {
  max-width: 75%;
}

[data-is-mobile] :is(.assistantMessgage, .userMessage) {
  max-width: 100%;
}

/**
 * ------------------------------------------------------------------------------------------------
 * CHAT INPUT SECTION
 * ------------------------------------------------------------------------------------------------
 */

.chatField:has([data-input-prefix]) [data-input-prefix],
.chatField:has([data-input-suffix]) [data-input-suffix] {
  --icon-size: calc(
    var(--body-line-height) + var(--body-margin-start) + var(--body-margin-end) +
      var(--inner-spacing-3) * 2
  );
  --icon-offset: calc((var(--input-height) - var(--icon-size)) / 2);
  --icon-size: calc(
    var(--body-line-height) + var(--body-margin-start) + var(--body-margin-end) +
      var(--inner-spacing-3) * 2
  );
  --icon-offset: calc((var(--input-height) - var(--icon-size)) / 2);
  position: absolute;
}

/** The has selector is used here to add more specificity */
.chatField:has([data-input-prefix]) [data-input-prefix] {
  bottom: var(--icon-offset);
  left: var(--icon-offset);
  margin-inline-start: 0;
}

/** The has selector is used here to add more specificity */
.chatField:has([data-input-suffix]) [data-input-suffix] {
  bottom: round(up, var(--icon-offset), 0.5px);
  right: var(--icon-offset);
  margin-inline-end: 0;
}

.chatInput:is(textarea) {
  min-block-size: initial;
  margin-block: var(--inner-spacing-3);
}

/* Note: using this long selector to increase the specificity of the selector to override the padding-block of the textarea */
.chatField [role="group"] .chatInput:is(textarea) {
  padding-block: 0;
}

.disclaimer {
  color: var(--color-fg-neutral-subtle);
}

.disclaimer [data-icon] {
  align-self: self-start;
  flex-shrink: 0;
}

/**
 * ------------------------------------------------------------------------------------------------
 * CHAT DOCUMENT VIEWER
 * ------------------------------------------------------------------------------------------------
 */
.documentViewer {
  position: relative;
  height: 100%;
}

.documentViewerSpinner,
.sidebarError {
  margin-block-start: var(--inner-spacing-2);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--inner-spacing-4);
  padding: var(--inner-spacing-4);
}

/**
 * ------------------------------------------------------------------------------------------------
 * CHAT MESSAGE TOOL CALLS
 * ------------------------------------------------------------------------------------------------
 */
.toolCalls {
  display: flex;
  flex-direction: column;
  gap: var(--inner-spacing-3);
  flex: 1;
}

.suggestionButton[data-color="neutral"][data-variant="outlined"] {
  box-shadow: 0 calc(var(--inner-spacing-1) / 3) var(--inner-spacing-1) 0
    var(--color-shadow-elevation-3) !important;
  outline-color: var(--color-bd-elevation-3) !important;
}

.toolCallButton[data-color="neutral"][data-variant="outlined"] {
  box-shadow: 0 calc(var(--inner-spacing-1) / 4) var(--inner-spacing-1) 0
    var(--color-shadow-elevation-3) !important;
  outline-color: var(--color-bd-elevation-3) !important;
}

/**
 * ------------------------------------------------------------------------------------------------
 * CHAT CITATION BUTTON
 * ------------------------------------------------------------------------------------------------
 */
.citationButton {
  display: inline-flex;
  vertical-align: middle;
  margin-inline: var(--inner-spacing-1);
  counter-increment: citation-number;

  /** using !important to override  the styles of the button 
  as specificy of wds button is (0,5,0) and we won't be able surpasss that specificity */
  min-inline-size: var(--sizing-9) !important;
}

/** using !important to override  the styles of the [data-content] element 
  as specificy of wds button's [data-content] is (0,6,0) and we won't be able surpasss that specificity */
.citationButton [data-content] {
  padding-inline-start: var(--icon-size-2) !important;
}

.citationNumber::before {
  content: counter(citation-number);
}

/**
 * ==============================================
 * Dot Loader
 * ==============================================
 */
.dotLoader {
  display: flex;
  gap: var(--inner-spacing-2);
}

.dot {
  width: 6px;
  height: 6px;
  background-color: var(--color-fg-neutral);
  border-radius: 50%;
  animation: bounce 1.2s infinite ease-in-out;
}

.dot:nth-child(2) {
  animation-delay: 0.1s;
}

.dot:nth-child(3) {
  animation-delay: 0.2s;
}

@keyframes bounce {
  0%,
  60%,
  100% {
    transform: translateY(0);
  }

  30% {
    transform: translateY(-6px);
  }
}

/**
 * ==============================================
 * Stop Button
 * ==============================================
 * This is a temporary fix to override the height and width of the stop button.
 */
.stopButton {
  svg {
    height: 14px !important;
    width: 14px !important;
  }
}
